import React from 'react'
import { Link } from 'react-router-dom'
import FooterComponent from '../Components/footer.component'
import AnimateComponent from '../Components/animate.component'
import HeaderComponent from '../Components/header.component'
import TransitionComponent from '../Components/transition.component'
import FeatherIcon from 'feather-icons-react'

export default function MembershipPage() {
  return (
    <TransitionComponent>
        <HeaderComponent />
            <AnimateComponent className="error-page column32">
                <h1 className='error-title'>Coming Soon</h1>
                <p className='error-text'>
                    Membership Page coming soon
                </p>
                <Link to={`/home`} className='error-button'>Go to Home <FeatherIcon icon='arrow-up-right' size={22} /></Link>
            </AnimateComponent>
        <FooterComponent />
    </TransitionComponent>
  )
}
