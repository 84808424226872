const resourcesList = [
    {
        "id": 0,
        "profile_path": "./Assets/Images/Guest Speakers/Dr Nana Arthur-Mensah.jpg",
        "author": "Dr Nana Arthur-Mensah",
        "title": "Enhancing Intercultural Competency",
        "information": "Intercultural skills refer to the ability to interact effectively with people from different cultural backgrounds.",
        "pdf_path": "./Assets/Pdf/Exchange/InterCultural Competencies- Nana Arthur-Mensah.pdf",
        "location": "Northern Kentucky University",
        "resource": "Intercultural Skills",
        "presentation": "Enhancing Cultural Competency",
        "profile_url": "https://www.nku.edu/academics/artsci/about/departments/pscjol/about/faculty-and-staff/NanaArthurMensah.html",
    },
    {
        "id": 1,
        "profile_path": "./Assets/Images/Guest Speakers/Dr Noro Andriamanalina.jpg",
        "author": "Dr Noro Andriamanalina",
        "title": "Proactive planning for Professional Development",
        "information": "Capacity Strengthening refers to an international process of developing new  knowledge, skills, attitudes, systems, and structures to assist an individual, group, organization, network, or system in functioning effectively and working towards achieving goals and sustainability.",
        "pdf_path": "./Assets/Pdf/Exchange/Proactive Planning.pdf",
        "location": "University Of Minnesota",
        "resource": "Capacity Strengthening",
        "presentation": "Getting More than a Degree: Proactive Planning for Professional Development",
        "profile_url": "https://www.linkedin.com/in/noroandriamanalina/",
    },
    {
        "id": 2,
        "profile_path": "./Assets/Images/Guest Speakers/Dr Meixi.png",
        "author": "Dr Meixi",
        "title": "Being in Story: Research & Life Work",
        "information": "Research involves investigating, studying, and analyzing a topic or problem to gain new perspectives, insights, or understandings.",
        "pdf_path": "./Assets/Pdf/Exchange/Alimah international PhD exchange.pdf",
        "location": "University Of Minnesota",
        "resource": "Research",
        "presentation": "Being in Story: Research and Life Work",
        "profile_url": "https://olpd.umn.edu/meixi",
    },
    {
        "id": 3,
        "profile_path": "./Assets/Images/Guest Speakers/Dr Nicole Dillard.jpg",
        "author": "Dr Nicole Dillard",
        "title": "Unveiling the Hidden Curriculum: Empowering Female Scholars to Navigate the Academy",
        "information": "As female PhD students navigate the complex landscape of academia they must skillfully address the unique challenges posed by gender dynamics. This introductory section lays the ground work for understanding and overcoming he obstacles women face in pursuing scholarly excellence.",
        "pdf_path": "./Assets/Pdf/Exchange/Gender & PhD presentation_Dillard.pdf",
        "location": "University Of Minnesota",
        "resource": "Academia",
        "presentation": "Unveiling the hidden Curriculum - Enabling Female Scholars to navigate the Academy",
        "profile_url": "https://olpd.umn.edu/nicole-dillard",
    },
];

export default resourcesList;