import React from 'react';
import { motion } from "framer-motion";
import TransitionComponent from '../Components/transition.component';
import FooterComponent from '../Components/footer.component';
import HeaderComponent from '../Components/header.component';
import AccordionComponent from '../Components/accordion.component';

export default function FaqPage() {
    const accordion_list = [
        {
            "name": "Why graduate students and scholars?",
            "description": "Educational support and assistance for resource-constrained countries have been concentrated on primary education by international aid organizations. In the last 20 years, there has been an increase in female undergraduates from the global south but not enough support for female graduate education and beyond.",
        },
        {
            "name": "Is this for Global South female students to pursue graduate education in high-income countries?",
            "description": "This work supports female scholars who stay in their countries for graduate education and those who also want to seek graduate education beyond their country",
        },
        {
            "name": "How do you support female graduate students in resource constrained countries?",
            "description": "We have resources, webinars, exchanges, and mentorship programs with female scholars in resourced countries to assist in entering, through, and beyond graduate education.",
        },
        {
            "name": "Do you assist with financing graduate education for females?",
            "description": "The organization is not a funding institution for graduate education. It builds the capacity of female scholars to search and apply for graduate education funding,",
        },
        {
            "name": "I have graduated with a PhD in the Global South. How can I benefit from your work?",
            "description": "The emerging researcher's program can assist you in connecting to female scholars in your field for mentorship, collaborations, publishing, and communicating your work.",
        },
        {
            "name": "How can I benefit as a female scholar or graduate student from a resourced country?",
            "description": "You can be a mentor, participate in our exchange or mentorship program, connect with an emerging researcher in your field for collaborations, present a webinar, prepare a resource for other scholars, or volunteer in our organization. In addition, you gain the cultural competency skills necessary for the global village that the world has become.",
        },
    ];
    
    return (
        <TransitionComponent>
            <HeaderComponent />
            <motion.section className="section secondary"
                initial={{ opacity: 0, transform: `translateY(240px)` }}
                whileInView={{ opacity: 1, transform: `translateY(0px)` }}
                viewport={{ once: true }}
                transition={{ duration: 1 }}
            >
                <div className="column32 stretch fill">
                    <h1 className="title-text white">FAQ</h1>
                    <div className='divider white' />
                    <div className='column'>
                        {
                            accordion_list.map((val) =>
                                <AccordionComponent title={val.name}>
                                    <p className='primary-text'>{val.description}</p>
                                </AccordionComponent>)
                        }
                    </div>
                </div>
            </motion.section>
            <FooterComponent />
        </TransitionComponent >
    );
}
