import FeatherIcon from 'feather-icons-react';
import { motion, AnimatePresence } from 'framer-motion';
import React from 'react';


const AccordionComponent = (props) => {
    const [expanded, setExpanded] = React.useState(false);

    // By using `AnimatePresence` to mount and unmount the contents, we can animate
    // them in and out while also only rendering the contents of open accordions
    return (
        <div className='accordion'>
            <motion.header
                initial={false}
                onClick={() => setExpanded(expanded ? false : true)}
                className='flex-auto'
            >

                <p className='primary-text'><strong>{props.title}</strong></p>
                <motion.span initial={false} animate={{ transform: expanded ? 'rotateZ(180deg)' : 'rotateZ(0)', transformStyle: "preserve-3d", }} transition={{ duration: 1 }}>
                    <FeatherIcon icon='chevron-down' size={22} />
                </motion.span>

            </motion.header>
            <AnimatePresence initial={false}>
                {expanded && (
                    <motion.footer
                        key="content"
                        initial="collapsed"
                        animate="open"
                        exit="collapsed"
                        variants={{
                            open: { opacity: 1, height: "auto" },
                            collapsed: { opacity: 0, height: 0 }
                        }}
                        transition={{ duration: 0.8, ease: [0.04, 0.62, 0.23, 0.98] }}
                        className='accordion-content'
                    >
                        {props.children}
                    </motion.footer>
                )}
            </AnimatePresence>
        </div>
    );
};
export default AccordionComponent;