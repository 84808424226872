import React from 'react';
import TransitionComponent from '../../Components/transition.component';
import HeaderComponent from '../../Components/header.component';
import FooterComponent from '../../Components/footer.component';
import { AnimatePresence, motion } from "framer-motion";
import resourcesList from '../../Constants/resources';
import feedbackList from '../../Constants/feedback';
import FeatherIcon from 'feather-icons-react';
import AnimateComponent from '../../Components/animate.component';

const FeedBox = ({ name, message }) => {
    const [isOpen, setIsOpen] = React.useState(false);

    const toggleClamp = (ev) => {
        ev.preventDefault();
        setIsOpen(!isOpen);
    }
    return <AnimatePresence initial={false}>
        <motion.div className="box fill primary">
            <div className="column8">
                <p className={`primary-text white ${!isOpen && 'clamp'}`}>
                    {message}
                </p>
                <motion.p className="primary-text white"
                    initial={{ opacity: 0, height: 0 }}
                    animate={isOpen ? { opacity: 1, height: "auto" } : { opacity: 0, height: 0 }}
                    transition={{ ease: "linear", duration: 0.3 }}
                >
                    <strong>{name}</strong>
                </motion.p>
                <button onClick={toggleClamp} className="btn primary-text white"> {!isOpen ? "Hear More" : "Hear Less"} </button>
            </div>
        </motion.div>
    </AnimatePresence>
}

export default function ExchangesRecordPage() {
    const [isOpen, setIsOpen] = React.useState(false);

    const toggleMenu = (ev) => {
        ev.preventDefault();
        setIsOpen(!isOpen);
    }

    const variants = {
        open: { opacity: 1, height: "auto" },
        collapsed: { opacity: 0, height: 0 },
    }
    return (
        <TransitionComponent>
            <HeaderComponent />
            <motion.section className="section secondary"
                initial={{ opacity: 0, transform: `translateY(360px)` }}
                whileInView={{ opacity: 1, transform: `translateY(0px)` }}
                viewport={{ once: true }}
                transition={{ duration: 1 }}>
                <div className="column16 fill stretch">
                    <h1 className="title-text center white">Female PhD Virtual Exchanges</h1>
                    <h1 className="title-text center white">March 22 - May 24, 2024</h1>
                </div>
            </motion.section>
            <motion.section className='section primary'
                initial={{ opacity: 0, transform: `translateY(360px)` }}
                whileInView={{ opacity: 1, transform: `translateY(0px)` }}
                viewport={{ once: true }}
                transition={{ duration: 1 }}>
                <div className="column32 fill stretch">
                    <h1 className="subtitle-text white">Guests Speakers</h1>

                    <div className="flex16">
                        {resourcesList.map((resource, index) => <div className="col3">
                            <div className="card column-auto column32">
                                <div className='card-image round-card'>
                                    <img
                                        loading="lazy"
                                        alt=""
                                        src={resource.profile_path}
                                        className="image-fluid center" />
                                </div>
                                <div className="card-content">
                                    <div className='column16 stretch'>
                                        <p className="primary-text center">
                                            <strong>{resource.author}</strong>
                                        </p>
                                        <p className="primary-text center clamp2">
                                            {resource.presentation}
                                        </p>
                                        <a
                                            href={resource.profile_url}
                                            target="_blank"
                                            className="primary-text center" rel="noreferrer"
                                        >
                                            Profile
                                        </a>
                                    </div>
                                </div>
                            </div>
                        </div>)}
                    </div>

                    <div className="flex32 flex-center">
                        <div className="column stretch">
                            <div className="round-button secondary">
                                <h1 className="hero-title white">19</h1>
                            </div>

                            <p className="subtitle-text white center title-top">total participants</p>
                        </div>

                        <div className="column stretch">
                            <button className="round-button">
                                <h1 className="hero-title white">12</h1>
                            </button>

                            <p className="subtitle-text white center title-top">from Africa</p>
                        </div>

                        <div className="column stretch">
                            <button className="round-button">
                                <h1 className="hero-title white">7</h1>
                            </button>

                            <p className="subtitle-text white center title-top">from the US</p>
                        </div>

                        <div className="column stretch">
                            <motion.button
                                className="round-button sm"
                                onClick={toggleMenu}
                                initial={{ rotateZ: "-90deg" }}
                                animate={isOpen ? { rotateZ: "0deg" } : { rotateZ: "-90deg" }}
                                transition={{ ease: "linear", duration: 0.3 }}
                            >
                                <FeatherIcon icon='chevron-down' size={56} />
                            </motion.button>

                            <p className="subtitle-text white center title-top">Expand</p>
                        </div>
                    </div>

                    <AnimatePresence initial={false}>
                        <motion.div
                            key="content"
                            className="flex-auto"
                            initial="collapsed"
                            animate={isOpen ? "open" : "collapsed"}
                            transition={{
                                duration: 0.5, ease: "easeInOut",
                            }}
                            variants={variants}
                        >
                            <div className="col8">
                                <div className="box primary secondary-border-left">
                                    <div className="column32">
                                        <h1 className="subtitle-text white">From Africa</h1>
                                        <div className="grid3x3 fill">
                                            <a href="#!" className="primary-text white">
                                                Rufaro Brenda Kawondera
                                            </a>
                                            <a href="#!" className="primary-text white">
                                                Temptation Gwangwava
                                            </a>
                                            <a href="#!" className="primary-text white">
                                                Rahabhi Mashapure
                                            </a>
                                            <a href="#!" className="primary-text white">
                                                Constance Chingwaru
                                            </a>
                                            <a href="#!" className="primary-text white">
                                                Nthabeleng Hlapsi
                                            </a>
                                            <a href="#!" className="primary-text white">
                                                Caroline Tendai Muguti
                                            </a>
                                            <a href="#!" className="primary-text white">Sharon Chisango</a>
                                            <a href="#!" className="primary-text white">Munira Hoosain </a>
                                            <a href="#!" className="primary-text white">Irene Walter </a>
                                            <a href="#!" className="primary-text white">Eunice Dhokura </a>
                                            <a href="#!" className="primary-text white">Jefline Kodzwa </a>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div className="col4">
                                <div className="box primary secondary-border-left">
                                    <div className="column32">
                                        <h1 className="subtitle-text bold white">From The US</h1>
                                        <div className="grid3x2 fill">
                                            <a href="#!" className="primary-text white">
                                                Ophelia Delali Akoto
                                            </a>
                                            <a href="#!" className="primary-text white">Elise Miller </a>
                                            <a href="#!" className="primary-text white">
                                                Elizabeth Buckhold
                                            </a>
                                            <a href="#!" className="primary-text white"> Emmerline Nelson Rodgers </a>
                                            <a href="#!" className="primary-text white"> Monique Burey-Ballard
                                            </a>
                                            <a href="#!" className="primary-text white">
                                                Idalia T. Wilmoth
                                            </a>
                                            <a href="#!" className="primary-text white">Jenai Johnson </a>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </motion.div>
                    </AnimatePresence>
                </div>
            </motion.section>
            <motion.section className='section secondary'
                initial={{ opacity: 0, transform: `translateY(360px)` }}
                whileInView={{ opacity: 1, transform: `translateY(0px)` }}
                viewport={{ once: true }}
                transition={{ duration: 1 }}>
                <div className="column32">
                    <div className="column16">
                        <h1 className="subtitle-text white">Beyond Formal Program</h1>

                        <p className="primary-text white">
                            Every two weeks, the participants met for two hours via Zoom.
                            During the first hour, participants discussed issues relevant to
                            them as female PhD students, such as differences in program
                            formats between US and British PhD programs, and ways to engage
                            the group further. The second hour of the meeting was devoted to a
                            presentation by a guest speaker. Participants requested a final
                            gathering to share their work and discuss strategies for further
                            engagement. In that Zoom meeting, five participants each presented
                            their research for five minutes, while the others answered
                            questions or offered suggestions. Based on the participants' needs
                            and skill assessments, they decided to hold a Grant Resources
                            workshop because many needed the ability to search for and write
                            research grant submissions. Four participants who had experience
                            volunteered to be facilitators of the workshop,<br />
                            The four presenters facilitated the Grant Resources workshop on
                            June 14, 2024. Here is the{" "}
                            <a
                                href="https://zoom.us/rec/play/SUmPsLJSPKHYVOFhfd-mZmiySm-SQ0C3O3ytUd_O0iND47lq2PaRfmi16UXt7Nf9p3lhcPgDVDvD3Odx.MHVzPruWC8lJRiuF?canPlayFromShare=true&amp;from=share_recording_detail&amp;continueMode=true&amp;iet=k99X35rE4Cr6J_ODRqoWkTKhgURXdenQj4R5b8ob7Q4.AG.hLvFhNDwlt8JLJ8cO9OfjT6fZ4ZWNlIDPONjK7YF5W9M5SJYQVrBKmz2FwuK8h4ZTBN1sU2UWI-5igbHb-I7kVJLbnNZQN3ETQd7Ujbmjr76JE_5R2-vznpzTAJwCEMvPWE.J2XF9L9p-YdliMGlj14LxA.Z0pzU_T1APj6JJxC&amp;componentName=rec-play&amp;originRequestUrl=https%3A%2F%2Fzoom.us%2Frec%2Fshare%2FefKkgTOl26kwYNhIlr9i2HLfmy2kVncDuJeVCijWc1bajZUUw4m3I6rPcSuEQ1kt.PVPQU2pO_gdF8Krs%3Fiet%3Dk99X35rE4Cr6J_ODRqoWkTKhgURXdenQj4R5b8ob7Q4.AG.hLvFhNDwlt8JLJ8cO9OfjT6fZ4ZWNlIDPONjK7YF5W9M5SJYQVrBKmz2FwuK8h4ZTBN1sU2UWI-5igbHb-I7kVJLbnNZQN3ETQd7Ujbmjr76JE_5R2-vznpzTAJwCEMvPWE.J2XF9L9p-YdliMGlj14LxA.Z0pzU_T1APj6JJxC&amp;fbclid=IwY2xjawENMO5leHRuA2FlbQIxMQABHUueWYElIfo-cKwPloiXoFbn1aGSexQfdZ3-Np4I8CEvELIINCT0cJXKXw_aem_PKsjfZw6Kwx5fv1sr4NU0w"
                                target="_blank"
                                className="primary-text white" rel="noreferrer"
                            >
                                link
                            </a>{" "}
                            to the workshop.
                        </p>
                    </div>

                    <div className="column16">
                        <h1 className="subtitle-text white">Participation Feedback</h1>

                        <div className="grid3x2 fill">
                            {feedbackList.map((feedback, index) =>
                                <AnimateComponent>
                                    <FeedBox name={feedback.name} message={feedback.message} />
                                </AnimateComponent>
                            )}
                        </div>
                    </div>
                </div>
            </motion.section>
            <FooterComponent />
        </TransitionComponent >
    )
}
