import React from 'react';
import { motion } from "framer-motion";

const TransitionComponent = ({ children }) => {
    return (
        <motion.div
            variants={{
                initial: { opacity: 0, transform: `translateY(240px)` },
                animate: { opacity: 1, transform: `translateY(0px)` },
                exit: { opacity: 0, transform: `translateY(240px)` },
            }}
            initial="initial"
            animate="animate"
            exit="exit"
            transition={{ duration: 1 }}>
            <div className='column stretch'>{children}</div>
        </motion.div>
    );
};
export default TransitionComponent;