
import React from 'react';
import { Video } from 'reactjs-media';

export const VideoPlayerComponent = (props) => {

    const videoJsOptions = {
        autoplay: false,
        controls: true,
        responsive: true,
        fluid: true,
        width: "100%",
        height: "100%",
    };

    return (
        <Video
            className="vjs-theme"
            {...props.options}
            {...videoJsOptions}
        />
    );
}

export default VideoPlayerComponent;