import React from 'react';
import { Link, NavLink } from 'react-router-dom';
import { AnimatePresence, motion } from "framer-motion";
import FeatherIcon from 'feather-icons-react';
import { useMediaQuery } from '@mui/material';

const DropdownComponent = (props) => {
    const [isOpen, setIsOpen] = React.useState(false);

    // By using `AnimatePresence` to mount and unmount the contents, we can animate
    // them in and out while also only rendering the contents of open accordions
    const toggleDropdown = (ev) => {
        ev.preventDefault();
        setIsOpen(!isOpen);
    }

    return (
        <div className='nav-dropdown'>
            <Link className="nav-item" onClick={toggleDropdown}>{props.name}<FeatherIcon icon="chevron-down" size={22} /> </Link>
            <AnimatePresence initial={false}>
                {isOpen && (
                    <motion.div
                        key="content"
                        initial="collapsed"
                        animate="open"
                        exit="collapsed"
                        variants={{
                            open: { opacity: 1, height: "auto" },
                            collapsed: { opacity: 0, height: 0 }
                        }}
                        transition={{ duration: 0.8, ease: [0.04, 0.62, 0.23, 0.98] }}
                        className='dropdown-menu'
                    >
                        {props.dropdown_list.map((val) =>
                            <NavLink to={val.path} className='dropdown-item'>{val.name}</NavLink>
                        )}
                    </motion.div>
                )}
            </AnimatePresence>
        </div>
    );
};

const HeaderComponent = () => {

    const [isOpen, setIsOpen] = React.useState(false);

    const dropdownMenu1 = [
        {
            "name": "About Us",
            "path": "/about",
        },
        {
            "name": "Our Team",
            "path": "/team",
        }
    ];
    const dropdownMenu2 = [
        {
            "name": "Mentorship",
            "path": "/mentorship",
        },
        {
            "name": "Exchanges",
            "path": "/exchanges",
        },
        {
            "name": "Membership ",
            "path": "/membership",
        }
    ];
    const isMobile = useMediaQuery('(max-width:1024px)');

    const toggleMenu = (ev) => {
        ev.preventDefault();
        setIsOpen(!isOpen);
    }


    const navVariants = {
        open: { opacity: 1, height: "auto" },
        collapsed: { opacity: 0, height: 0 },
        exit: { opacity: 0, height: 0 },
    };

    return (
        <motion.header className='navbar'
            initial={{ opacity: 0, transform: `translateY(240px)` }}
            whileInView={{ opacity: 1, transform: `translateY(0px)` }}
            viewport={{ once: true }}
            transition={{ duration: 1 }}
        >
            <NavLink to={`/`} className='navbar-brand'>
                <img loading="lazy" className="image-fluid contain" alt='Alimah International logo' src='./Assets/Images/Logo/Logo.png' />
            </NavLink>
            {isMobile ? <AnimatePresence initial={false}>
                <motion.nav className='navbar-menu'
                    key="content"
                    initial="exit"
                    animate={isOpen === true ? "open" : "collapsed"}
                    variants={navVariants}
                    transition={{ ease: "easeInOut", duration: 0.3 }}>
                    <NavLink to={`/home`} className='nav-item'>Home</NavLink>
                    <DropdownComponent name="Who We Are" dropdown_list={dropdownMenu1} />
                    <DropdownComponent name="What We Do" dropdown_list={dropdownMenu2} />
                    <NavLink to={`/resources`} className='nav-item'>Resources</NavLink>
                    <NavLink to={`/faqs`} className='nav-item'>FAQs</NavLink>
                </motion.nav>
            </AnimatePresence> : <nav className='navbar-menu'>
                <NavLink to={`/home`} className='nav-item'>Home</NavLink>
                <DropdownComponent name="Who We Are" dropdown_list={dropdownMenu1} />
                <DropdownComponent name="What We Do" dropdown_list={dropdownMenu2} />
                <NavLink to={`/resources`} className='nav-item'>Resources</NavLink>
                <NavLink to={`/faqs`} className='nav-item'>FAQs</NavLink>
            </nav>}

            {
                isMobile &&
                <p onClick={toggleMenu} className="primary-text white">
                    <FeatherIcon icon='menu' size={22} />
                </p>
            }
        </motion.header>
    );
}

export default HeaderComponent;