import React from 'react';
import TransitionComponent from '../Components/transition.component';
import HeaderComponent from '../Components/header.component';
import FooterComponent from '../Components/footer.component';
import { AnimatePresence, motion } from "framer-motion";
import emailjs from "@emailjs/browser";

export default function ContactPage() {
    const formRef = React.useRef();
    const [name, handleName] = React.useState("");
    const [email, handleEmail] = React.useState("");
    const [message, handleMessage] = React.useState("");
    const [isSubmitted, SetIsSubmitted] = React.useState(false);
    const [isError, SetIsError] = React.useState(true);
    const [btnText, handleBtnText] = React.useState("Submit");
    React.useEffect(() => {
        document.title = "Contact Us for Inquiries";

    }, []);

    const handleSubmit = (ev) => {
        ev.preventDefault();

        handleBtnText('Sending....');

        const serviceID = 'service_4ouedsx';
        const templateID = 'alimah_x1mtpir';

        emailjs
            .sendForm(serviceID, templateID, formRef.current, { publicKey: "7JMkBq88QD4RBND8r" })
            .then(
                () => {
                    SetIsSubmitted(true);
                    SetIsError(false);


                    handleBtnText('Submit');
                },
                (err) => {
                    SetIsSubmitted(true);
                    SetIsError(true);

                    handleBtnText('Submit');
                    console.log(JSON.stringify(err));
                }
            );

        return false;
    };

    return (
        <TransitionComponent>
            <HeaderComponent />

            <motion.section className="section secondary"
                initial={{ opacity: 0, transform: `translateY(240px)` }}
                whileInView={{ opacity: 1, transform: `translateY(0px)` }}
                viewport={{ once: true }}
                transition={{ duration: 1 }}
            >
                <div className="column32 stretch fill">
                    <div className='column16'>
                        <h6 className="subtitle-text white">Contact Us</h6>
                        <h1 className="title-text white">Get In Touch With US</h1>
                        <div className='divider white' />
                    </div>
                    {((isSubmitted === false && isError === true) ||
                        (isSubmitted === true && isError === true)) && (
                            <form ref={formRef} className="box" onSubmit={handleSubmit}>
                                <div className='column32 stretch fill'>
                                    {/* Name */}
                                    <div className='column8 stretch'>
                                        <label className='primary-text'>
                                            <strong>Name:</strong>
                                        </label>
                                        <input
                                            className="form-control"
                                            autoFocus={true}
                                            maxLength="256"
                                            name="user_name"
                                            placeholder="Enter name...."
                                            type="text"
                                            required
                                            value={name}
                                            onChange={(ev) => {
                                                ev.preventDefault();
                                                handleName(ev.target.value);
                                            }} />
                                    </div>
                                    {/* Email Address */}
                                    <div className='column8 stretch'>
                                        <label className='primary-text'>
                                            <strong>Email Address:</strong>
                                        </label>
                                        <input
                                            className="form-control"
                                            maxLength="256"
                                            name="user_email"
                                            placeholder="Enter email...."
                                            type="email"
                                            required
                                            value={email}
                                            onChange={(ev) => {
                                                ev.preventDefault();
                                                handleEmail(ev.target.value);
                                            }} />
                                    </div>
                                    {/* Message */}
                                    <div className='column8 stretch'>
                                        <label className='primary-text'>
                                            <strong>Message:</strong>
                                        </label>
                                        <textarea
                                            placeholder="Leave your message here..."
                                            maxLength="5000"
                                            name="message"
                                            className="form-control"
                                            value={message}
                                            onChange={(ev) => {
                                                ev.preventDefault();
                                                handleMessage(ev.target.value);
                                            }} />
                                    </div>
                                    {/* Submit */}
                                    <div className='column8'>
                                        <input type='submit' value={btnText} className='primary-button' />
                                    </div>
                                </div>
                            </form>
                        )}

                    <AnimatePresence initial={false}>
                        {isSubmitted === true && isError === false && (
                            <motion.div
                                className="success-message"
                                initial={{ transform: `translateY(240px)` }}
                                animate={{ transform: `translateY(0px)` }}
                                transition={{ duration: 0.5, ease: "linear" }}
                            >
                                <p className="primary-text">
                                    Thank you! Your submission has been received!
                                </p>
                            </motion.div>
                        )}

                        {isSubmitted === true && isError === true && (
                            <motion.div
                                className="error-message"
                                initial={{ transform: `translateY(240px)` }}
                                animate={{ transform: `translateY(0px)` }}
                                transition={{ duration: 0.5, ease: "linear" }}
                            >
                                <p className="primary-text">
                                    Oops! Something went wrong while submitting the
                                    form.
                                </p>
                            </motion.div>
                        )}
                    </AnimatePresence>
                </div>
            </motion.section>
            <FooterComponent />
        </TransitionComponent>
    )
}
