import * as React from 'react';
import HeaderComponent from '../Components/header.component';
import FooterComponent from '../Components/footer.component';
import { Link } from 'react-router-dom';
import FeatherIcon from 'feather-icons-react';
import { motion } from 'framer-motion';
import TransitionComponent from '../Components/transition.component';

const HomePage = (props) => {
    return (
        <TransitionComponent>
            <HeaderComponent />
            <motion.section className='hero'
                initial={{ opacity: 0, transform: `translateY(360px)` }}
                whileInView={{ opacity: 1, transform: `translateY(0px)` }}
                viewport={{ once: true }}
                transition={{ duration: 1 }}
            >
                <div className='column64 fill stretch'>
                    <div className='column32 stretch'>
                        <h1 className='hero-title center white'>Sharing the mist</h1>
                        <h2 className='hero-subtitle center white'>Building a community of globally and culturally competent female scholars</h2>
                    </div>
                    <div className='flex32'>
                        {/* Exchanges */}
                        <div className='col4'>
                            <div className='card'>
                                <div className='card-image'>
                                    <img alt='Exchanges' src='https://cdn.prod.website-files.com/668ab66e9c5572bfad197e28/668ab69a160abcbd3332b3e3_exchange.svg' className='image-fluid contain' />
                                </div>
                                <div className='card-content'>
                                    <Link to={`/exchanges`} className='primary-button round'>Exchanges <FeatherIcon icon='arrow-up-right' size={24} /></Link>
                                </div>
                            </div>
                        </div>
                        {/* Mentorship */}
                        <div className='col4'>
                            <div className='card'>
                                <div className='card-image'>
                                    <img alt='Mentorship' src='https://cdn.prod.website-files.com/668ab66e9c5572bfad197e28/668ab69b160abcbd3332b450_mentorship.svg' className='image-fluid contain' />
                                </div>
                                <div className='card-content'>
                                    <Link to={`/mentorship`} className='primary-button round'>Mentorship <FeatherIcon icon='arrow-up-right' size={24} /></Link>
                                </div>
                            </div>
                        </div>
                        {/* Resources */}
                        <div className='col4'>
                            <div className='card'>
                                <div className='card-image'>
                                    <img alt='Resources' src='https://cdn.prod.website-files.com/668ab66e9c5572bfad197e28/668ab69a160abcbd3332b449_resources.svg' className='image-fluid contain' />
                                </div>
                                <div className='card-content'>
                                    <Link to={`/resources`} className='primary-button round'>Resources <FeatherIcon icon='arrow-up-right' size={24} /></Link>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className='hero-images'>
                        <img
                            loading="lazy"
                            src="https://cdn.prod.website-files.com/668ab66e9c5572bfad197e28/668ab69b160abcbd3332b466_Union.png"
                            alt=""
                        />
                        <img
                            loading="lazy"
                            src="https://cdn.prod.website-files.com/668ab66e9c5572bfad197e28/668ab69b160abcbd3332b466_Union.png"
                            alt=""
                        />
                        <img
                            loading="lazy"
                            src="https://cdn.prod.website-files.com/668ab66e9c5572bfad197e28/668ab69b160abcbd3332b466_Union.png"
                            alt=""
                        />
                    </div>
                </div>
            </motion.section>
            <motion.section className="section"
                initial={{ opacity: 0, transform: `translateY(240px)` }}
                whileInView={{ opacity: 1, transform: `translateY(0px)` }}
                viewport={{ once: true }}
                transition={{ duration: 1 }}
            >
                <h2 className="title-text white">Our Why</h2>
                <div className="flex16">
                    <div className='col3'>
                        <div className="reason-card">
                            <p className="primary-text white">
                                <strong className="reason-text">Fewer</strong> women have access to intercultural competency skills.
                            </p>
                        </div>
                    </div>
                    <div className='col3'>
                        <div className="reason-card">
                            <p className="primary-text white">
                                Women have more <strong className="reason-text">challenges</strong> in accessing learning mobility compared to men.
                            </p>
                        </div>
                    </div>
                    <div className='col3'>
                        <div className="reason-card">
                            <p className="primary-text white">
                                Current institutional training does not foster an <strong className="reason-text">Ubuntu</strong> learning environment for females
                            </p>
                        </div>
                    </div>
                    <div className='col3'>
                        <div className="reason-card">
                            <p className="primary-text white">
                                Female graduate students <strong className="reason-text">lack</strong> connection to other female graduate students globally
                            </p>
                        </div>
                    </div>
                </div>
                <h2 className="title-text white">How</h2>
                <p className="primary-text white">
                    Alimah International was created to create authentic and discipline-agnostic connections of female
                    global scholars across institutional and geographical borders. Alimah International was founded in
                    response to a Shona proverb, <strong><em>"Zvikomo zvinopanana mhute"</em></strong> which means <strong><em>"big mountains share
                        mist"</em></strong>. This proverb encourages great minds to come together and share knowledge.
                </p>
                <h2 className="title-text white">Learn More From Our Founder</h2>
                <div className="video-container">
                    <iframe title="Alimah International Ted Talk" src={`https://www.youtube.com/embed/yQ_ood9OuOk?rel=0`} allowFullScreen />
                </div>
            </motion.section>
            <FooterComponent />
        </TransitionComponent>
    );
}

export default HomePage;
