import React from 'react';
import { motion } from 'framer-motion';
import { Link, useParams } from 'react-router-dom';
import resourcesList from '../../Constants/resources';
import HeaderComponent from '../../Components/header.component';
import FooterComponent from '../../Components/footer.component';
import TransitionComponent from '../../Components/transition.component';

export default function AboutResourcesPage() {
    let { resourcesId } = useParams();
    const resource = resourcesList.filter((res) => `${res.id}` === `${resourcesId}`)[0];

    return (
        <TransitionComponent>
            <HeaderComponent />

            <motion.section className="section secondary"
                initial={{ opacity: 0, transform: `translateY(240px)` }}
                whileInView={{ opacity: 1, transform: `translateY(0px)` }}
                viewport={{ once: true }}
                transition={{ duration: 1 }}>
                <div className="column64 stretch fill">
                    <div className="column16 stretch">
                        <h1 className="primary-title-text white">Resources - {resource.title}</h1>
                        <div className='divider white' />
                        <div className='flex32'>
                            <div className='col6'>
                                <div className='pdf-container'>
                                    <iframe src={resource.pdf_path} title={resource.title} />
                                </div>
                            </div>
                            <div className='col6'>
                                <div className='column32'>
                                    <div className='column16'>
                                        <h3 className='subtitle-text white'>Presented at {resource.location}</h3>
                                        <h3 className='subtitle-text white'>Presented by {resource.author}</h3>
                                    </div>
                                    <div className='column16'>
                                        <h3 className='subtitle-text white'>Additional information</h3>
                                        <h3 className='primary-text white'>{resource.information}</h3>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className='column16 stretch'>
                        <h1 className="subtitle-text white">More resources From This Event</h1>

                        <div className='flex16'>
                            {
                                resourcesList.filter((res) => res.author !== `${resource.author}`).map((resource) =>
                                    <div className='col6'>
                                        <Link to={`/resources/${resource.id}`} className="box fill">
                                            <div className="column16">
                                                <p className="subtitle-text overflow"><strong>{resource.title}</strong></p>
                                                <p className="subtitle-text overflow">
                                                    <strong>{resource.author}</strong>
                                                </p>
                                            </div>
                                        </Link>
                                    </div>
                                )
                            }
                        </div>
                    </div>
                </div>
            </motion.section>

            <FooterComponent />
        </TransitionComponent>
    )
}
