import React from 'react';
import { motion } from 'framer-motion';
import HeaderComponent from '../Components/header.component';
import FooterComponent from '../Components/footer.component';
import TransitionComponent from '../Components/transition.component';
import VideoPlayerComponent from '../Components/video.component';

export default function MentorshipPage() {
    return (
        <TransitionComponent>
            <HeaderComponent />

            <motion.section className="section secondary"
                initial={{ opacity: 0, transform: `translateY(240px)` }}
                whileInView={{ opacity: 1, transform: `translateY(0px)` }}
                viewport={{ once: true }}
                transition={{ duration: 1 }}>
                <div className="column64 stretch">
                    <div className="column16">
                        <h1 className="title-text white">Mentorship</h1>

                        <p className="primary-text white">
                            Mentorship program connects graduate students and/or emerging
                            researchers in low income countries with female graduate mentors and
                            or female researchers from high income countries. Low income female
                            graduate students and emerging researchers pursuing high level
                            careers requires building a network of researchers and mentors in
                            your field. Forming these connections can be difficult and
                            intimidating in any environment. It is our goal as Alimah
                            International to bridge the gap between low income and high income
                            country experiences of female graduate students and emerging
                            researchers. We believe that high level careers like STEM should be
                            accessible to every female and that it is the females who have
                            walked that path who can hold their sisters hand to also do the same
                            and welcome them into the global research community.
                        </p>
                    </div>
                    <div className="video-container">
                        <VideoPlayerComponent options={{
                            src: "./Assets/Videos/Mentor Testimonial from Mentorship Program 2023_1080p.mp4",
                            poster: "./Assets/Videos/Mentor Testimonial from Mentorship Program 2023_1080p.png",
                            title: "Mentor Testimonial from Mentorship Program 2023",
                        }} />
                    </div>
                    <div className="video-container">
                        <VideoPlayerComponent options={{
                            src: "./Assets/Videos/Mentee Testimonial of Mentorship Program 2023_360P.mp4",
                            poster: "./Assets/Videos/Mentee Testimonial of Mentorship Program 2023_360P.png",
                            title: "Mentee Testimonial of Mentorship Program 2023",
                        }} /> 
                    </div>
                    <div className="flex-auto">
                        <a
                            href="https://forms.gle/zT2MKB4V52oy5rZi8"
                            target="_blank"
                            className="link-box" rel="noreferrer"
                        >
                            <img
                                width="84"
                                height="Auto"
                                alt=""
                                src="https://cdn.prod.website-files.com/668ab66e9c5572bfad197e28/668ac272134dc172d639690a_mentor.svg"
                                loading="lazy"
                            />
                            Become A Mentor
                        </a>
                        <a
                            href="https://forms.gle/wi3ZvkjWDh9Rp6wC8"
                            target="_blank"
                            className="link-box" rel="noreferrer"
                        >
                            <img
                                width="84"
                                height="Auto"
                                alt=""
                                src="https://cdn.prod.website-files.com/668ab66e9c5572bfad197e28/668ac272134dc172d639691c_mentee.svg"
                                loading="lazy"
                            />
                            Become A Mentee
                        </a>
                    </div>
                </div>
            </motion.section>

            <FooterComponent />
        </TransitionComponent>
    )
}
