import React from 'react';
import { motion } from 'framer-motion';
import TransitionComponent from '../Components/transition.component';
import AnimateComponent from '../Components/animate.component';
import HeaderComponent from '../Components/header.component';
import FooterComponent from '../Components/footer.component';

export default function AboutPage() {
    const valuesList = [
        {
            icon: "./Assets/Images/Values/Curiosity.svg",
            text: "Curiosity",
        },
        {
            icon: "./Assets/Images/Values/Collaboration.svg",
            text: "Collaboration",
        },
        {
            icon: "./Assets/Images/Values/Inclusivity.svg",
            text: "Inclusive",
        },
        {
            icon: "./Assets/Images/Values/Empowerment.svg",
            text: "Empowerment",
        },
        {
            icon: "./Assets/Images/Values/Community.svg",
            text: "Community",
        },
    ]
    return (
        <TransitionComponent>
            <HeaderComponent />
            <motion.section className="section white"
                initial={{ opacity: 0, transform: `translateY(240px)` }}
                whileInView={{ opacity: 1, transform: `translateY(0px)` }}
                viewport={{ once: true }}
                transition={{ duration: 1 }}
            >
                <div className="column32 stretch">
                    <h1 className="title-text center">Who We Are</h1>
                    <div className="divider" />
                    <p className="primary-text">
                        According to UNESCO, global female enrollment in higher education
                        institutions has doubled in the last two decades. In 74% of the
                        countries, women are overrepresented except in Central and Southern
                        Asia where they are at par, and in sub-Saharan Africa males are
                        overrepresented. Hence, fewer female students are transitioning to
                        graduate education in these low-income regions. Graduate education
                        leads to positions in policymaking, and UN Women states that policies
                        become more gender-responsive and inclusive when women sit at the
                        table with policymakers. Investment in female education in low-income
                        countries has been concentrated at the primary level yet the gender
                        disparities do not disappear as one continues to graduate education.
                        <br />
                        <br />
                        Alimah International was created to work in this space after
                        inspiration from the spirit of Ubuntu in the Shona proverb "zvikomo
                        zvinopanana mhute, "which translates to" big mountains share
                        mist."Great minds can coexist like mountains to exchange ideas.ideas.
                        Alimah is an Arabic word meaning educated female, while International
                        represents females worldwide. The logo symbolizes the coming together
                        of two people (the double lines meeting together with one head), and
                        what connects these two people is the mist they share. This concept is
                        explained in greater detail through Alimah's founder, Enet
                        Mukurazita's{" "}
                        <a href="https://www.youtube.com/watch?v=yQ_ood9OuOk" target='_blank' rel="noreferrer"><strong>TEDx Talk.</strong></a>
                    </p>
                    <AnimateComponent className="box">
                        <div className="column16">
                            <h1 className="title-text">Mission</h1>
                            <p className="primary-text">
                                The mission of Alimah International is to foster genuine and
                                diverse connections among female scholars around the world,
                                regardless of their institutional affiliations or geographical
                                location
                            </p>
                        </div>
                    </AnimateComponent>
                    <AnimateComponent className="box">
                        <div className="column16">
                            <h1 className="title-text">Vision</h1>
                            <p className="primary-text">
                                We seek a global community of female scholars regardless of
                                location, culture or institution
                            </p>
                        </div>
                    </AnimateComponent>
                    <AnimateComponent className="box">
                        <div className="column16">
                            <h1 className="title-text">What Drives Us</h1>
                            <p className="primary-text">
                                We believe that living in the digital era presents a unique
                                opportunity for women from all over the world to join in community
                                in ways that they never have before. Female scholars, leaning into
                                the Ubuntu philosophy, can empower and be empowered to assist one
                                another into, through, and beyond graduate education.
                            </p>
                        </div>
                    </AnimateComponent>
                    <AnimateComponent className="column16">
                        <div className="title-text">Our Values</div>
                        <div className="divider" />
                    </AnimateComponent>
                    <AnimateComponent className="flex-auto fill">
                        {valuesList.map((value, i) =>
                            <AnimateComponent transition={{ ease: "easeInOut", delay: i - 1.75 }} className="column16 stretch">
                                <img
                                    loading="lazy"
                                    height="180"
                                    alt=""
                                    src={value.icon}
                                />
                                <p className="primary-text center">{value.text}</p>
                            </AnimateComponent>
                        )}
                    </AnimateComponent>
                    <AnimateComponent className="box">
                        <div className="column16">
                            <h1 className="title-text">We Work To</h1>
                            <ul>
                                <li>
                                    <p className="primary-text">
                                        Increase the number of women in resource-constrained
                                        communities to enter, thrive in, and complete graduate
                                        education.
                                    </p>
                                </li>
                                <li>
                                    <p className="primary-text">
                                        Provide a space for women in graduate education or their
                                        careers to develop intercultural skills through exchanges,
                                        mentorship, or webinars.
                                    </p>
                                </li>
                                <li>
                                    <p className="primary-text">
                                        Connect female scholars and researchers from low to
                                        middle-income countries with their counterparts in high-income
                                        countries.
                                    </p>
                                </li>
                                <li>
                                    <p className="primary-text">
                                        Avail resources for capacity building to female scholars from
                                        resource -constrained communities.
                                    </p>
                                </li>
                            </ul>
                        </div>
                    </AnimateComponent>
                </div>
            </motion.section>
            <FooterComponent />
        </TransitionComponent>
    )
}
