import React from 'react';
import { motion } from "framer-motion";
import TransitionComponent from '../Components/transition.component';
import FooterComponent from '../Components/footer.component';
import HeaderComponent from '../Components/header.component';
import { Link } from 'react-router-dom';

export default function ExchangesPage() {
    return (
        <TransitionComponent>
            <HeaderComponent />
            <motion.section className="section secondary"
                initial={{ opacity: 0, transform: `translateY(240px)` }}
                whileInView={{ opacity: 1, transform: `translateY(0px)` }}
                viewport={{ once: true }}
                transition={{ duration: 1 }}
            >
                <div className="column32 stretch">
                    <h1 className="title-text center white">Exchanges</h1>
                    <div className="flex16">
                        <div className="col4">
                            <div className="card noborder">
                                <div className="card-content column32">
                                    <div className="card-block">
                                        <a className="title-text tertiary" href='https://docs.google.com/forms/d/1FtuckoXO8RIUSydvUZfY-k4I_7bgeVffwtePPhWN19Y/edit' target='_blank' rel="noreferrer">
                                            <em>2025 Female STEM Virtual Exchange Applications</em>
                                        </a>
                                    </div>
                                    <h1 className="title-text">STEM Scholars Exchange</h1>
                                </div>
                            </div>
                        </div>
                        <div className="col4">
                            <div className="card noborder">
                                <div className="card-content column32">
                                    <div className="card-block column32">
                                        <Link to={`/exchange_history`} className="title-text tertiary">
                                            <em>March 22 - May 4 2024</em>
                                        </Link>
                                        <a href='https://forms.gle/mPWopbMmPJW13auS9' className="title-text tertiary" target='_blank' rel="noreferrer">
                                            <em>2025 Applications</em>
                                        </a>
                                    </div>
                                    <h1 className="title-text">Female Phd Virtual Exchange</h1>
                                </div>
                            </div>
                        </div>
                        <div className="col4">
                            <div className="card noborder">
                                <div className="card-content column32">
                                    <div className="card-block">
                                        <h2 className="title-text tertiary">
                                            <em>Coming Soon</em>
                                        </h2>
                                    </div>
                                    <h1 className="title-text">Emerging Researchers Exchange</h1>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="card noborder">
                        <div className="card-content column32 column32">
                            <h1 className="title-text">What Our Exchange Programs Do</h1>
                            <p className='primary-text'>
                                Women scholars in LMIC do not have access to professional
                                and graduate education development and access to journals
                                not only because of access but also because of support.
                                Education exchanges can assist with both access and support
                                while promoting technical, linguistic and intercultural
                                skill development requires students to succeed in the
                                current connected global world. However, due to financial,
                                gender, visa and cultural constraints in person exchanges
                                for women can be a challenge. Online exchange programs such
                                as STEM, Emerging researchers and Doctoral will provide a
                                space for women scholars from both hemispheres to take
                                advantage of the benefits of exchange programs.
                            </p>
                        </div>
                    </div>
                </div>
            </motion.section>
            <FooterComponent />
        </TransitionComponent >
    );
}
