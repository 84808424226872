import * as React from 'react';
import HeaderComponent from '../Components/header.component';
import FooterComponent from '../Components/footer.component';
import { motion } from 'framer-motion';
import TransitionComponent from '../Components/transition.component';
import AnimateComponent from '../Components/animate.component';

const TeamPage = (props) => {
    return (
        <TransitionComponent>
            <HeaderComponent />
            <motion.section className='section white'
                initial={{ opacity: 0, transform: `translateY(360px)` }}
                whileInView={{ opacity: 1, transform: `translateY(0px)` }}
                viewport={{ once: true }}
                transition={{ duration: 1 }}
            >

                <div className="column32 stretch">
                    <h1 className="title-text center">Our Team</h1>
                    <div className="divider" />
                    <AnimateComponent className="box">
                        <div className='flex32'>
                            <div className='round-image'>
                                <img className='image-fluid center' alt='Enet Mukurazita' src="./Assets/Images/Team/Enet Mukurazita.jpg" />
                            </div>
                            <div className="column16 col10">
                                <h1 className="title-text">Enet Mukurazita</h1>
                                <em className='subtitle-text'>Founder</em>
                                <p className="primary-text">
                                    Enet Mukurazita was born and raised in Zimbabwe, where her way of life was guided by the Ubuntu philosophy, which not only promotes living in community but also provides a learning theory where you learn from and with others. Attending graduate school in the US she leaned on this philosophy to help her navigate the hidden curriculum of higher education. It is the use of this African indigenous learning theory that she explains in her
                                    {" "}<a href="https://www.youtube.com/watch?v=yQ_ood9OuOk"><strong className="primarytext linktext">TEDx Talk</strong></a> that has made her a PhD candidate at the University of Minnesota, College of Education and Human Development, in the Organizational Leadership Policy and Development department. She has worked in Africa and North America in profit, non-profit, and educational institutions. As an African female scholar, she has lived experience in African and North American higher education institutions.
                                </p>
                            </div>
                        </div>

                    </AnimateComponent>

                    <AnimateComponent className="box">
                        <div className="column32">
                            <div className="title-text">Board Of Advisors</div>
                            <div className="divider dark" />
                            <AnimateComponent className="flex-auto flex32">
                                <div className='round-image'>
                                    <img
                                        alt=""
                                        src="./Assets/Images/Team/Iris Cumberbatch.jpg"
                                        loading="lazy"
                                        className="image-fluid center"
                                    />
                                </div>
                                <div className="column16 col10">
                                    <h1 className="subtitle-text bold">Iris Cumberbatch</h1>
                                    <p className="primary-text">
                                        Iris Cumberbatch is senior vice president and head of corporate
                                        affairs at ECMC Group. Dr.Cumberbatch has held this position since
                                        2016, leading a team of professionals responsible formedia
                                        relations, external communications, executive communications,
                                        government affairs,community relations, internal communications,
                                        web services, graphic design, and social anddigital media.Dr.
                                        Cumberbatch, a former vice president and head of public affairs at
                                        the Federal Reserve Bankof Cleveland,

                                        {" "}<strong className="link-text">Read More...</strong>
                                    </p>
                                </div>
                            </AnimateComponent>
                            <AnimateComponent className="flex-auto flex32">
                                <div className='round-image'>
                                    <img
                                        alt=""                                        
                                        src="./Assets/Images/Team/Kimberlie Mook.jpg"
                                        loading="lazy"
                                        className="image-fluid center"
                                    />
                                </div>
                                <div className="column16 col10">
                                    <h1 className="subtitle-text bold">Kimberlie Mook</h1>
                                    <p className="primary-text">
                                        Kimberlie is the Operations Coordinator for the Toaster Innovation
                                        Hub - a 6,500-square-foot space in the lower level of Walter
                                        Library promoting student-centered, multi-disciplinary
                                        collaboration in service to innovation and entrepreneurship.
                                        Kimberlie works to create an atmosphere and community that
                                        supports Toaster members and encourages active engagement in
                                        Toaster events. She is an avid connector of people, programs,
                                        resources, and ideas and has a passion for supporting individuals
                                        in activating and attaining their potential,
                                        {" "}<strong className="link-text">Read More...</strong>
                                    </p>
                                </div>
                            </AnimateComponent>
                            <AnimateComponent className="flex-auto flex32">
                                <div className='round-image'>
                                    <img
                                        alt=""
                                        src="./Assets/Images/Team/Dr. Joice Ndlovu.jpg"
                                        loading="lazy"
                                        className="image-fluid center"
                                    />
                                </div>
                                <div className="column16 col10">
                                    <h1 className="subtitle-text bold">Dr. Joice Ndlovu</h1>
                                    <p className="primary-text">
                                        Joice is a senior lecturer and chairperson in the biology
                                        department at Chinhoyi University of Technology in Zimbabwe. She
                                        is committed to educating girls and women, particularly in STEM
                                        fields. The underrepresentation of women in Africa's science
                                        landscape motivates her to promote science discussions and
                                        encourage girls and women to use science and technology for
                                        economic development. In most African countries, women are
                                        confined to agriculture and are unable to utilize science to
                                        improve &nbsp;
                                        {" "}<strong className="link-text">Read More...</strong>
                                    </p>
                                </div>
                            </AnimateComponent>
                            <AnimateComponent className="flex-auto flex32">
                                <div className='round-image'>
                                    <img
                                        alt=""
                                        src="./Assets/Images/Team/Megan Voorhess.jpeg"
                                        loading="lazy"
                                        className="image-fluid center"
                                    />
                                </div>
                                <div className="column16 col10">
                                    <h1 className="subtitle-text bold">Megan Voorhess</h1>
                                    <p className="primary-text">
                                        Megan's career has primarily focused on leading higher education
                                        and community programs designed to support participants to learn
                                        the skills and knowledge they need to effectively address complex
                                        social and environmental issues. She is currently a consultant and
                                        leadership coach and is the part-time Executive Director of the
                                        Upper Midwest Association for Campus Sustainability. Most
                                        recently, she was the Director of Experiential Education and
                                        Graduate Programs at the Institute on the Environment at the
                                        University &nbsp;&nbsp;

                                        {" "}<strong className="link-text">Read More...</strong>
                                    </p>
                                </div>
                            </AnimateComponent>
                        </div>
                    </AnimateComponent>
                </div>
            </motion.section>
            <FooterComponent />
        </TransitionComponent>
    );
}

export default TeamPage;
