import React from 'react';
import FeatherIcon from 'feather-icons-react';
import { Link, useRouteError } from 'react-router-dom';
import AnimateComponent from "../Components/animate.component";
import TransitionComponent from '../Components/transition.component';
import HeaderComponent from '../Components/header.component';
import FooterComponent from '../Components/footer.component';

const ErrorPage = (props) => {
    const error = useRouteError();
    return (
        <TransitionComponent>
            <HeaderComponent />
            <AnimateComponent className="error-page">
                <h1 className='error-title'>{error.status}</h1>
                <p className='error-text'>
                    {/* Sorry, an unexpected error has occurred.  */}
                    {error.statusText || error.message}
                </p>
                <i className='error-text'>
                    {error.message || error.data}
                    <br /><br />
                    {error.error.stack || error.message}
                </i>
                <Link to={`/home`} className='error-button'>Go to Home <FeatherIcon icon='arrow-up-right' size={22} /></Link>
            </AnimateComponent>
            <FooterComponent />
        </TransitionComponent>
    );
}

export default ErrorPage;
